/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

$color-size: 4em;
$color-border: .2em;
$color-core: $color-size - 2 * $color-border;

input.ng-invalid, select.ng-invalid, textarea.ng-invalid {
    background-color: #ffcccc;
}

input.ng-untouched, select.ng-untouched, textarea.ng-untouched {
    background-color: #ffffff;
}

%centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%)
}

%centered-absolute {
    position: absolute;
    left:$color-border;
    top:$color-border;
}

.color-border {
    margin: 4px;
    position: relative;
    border-radius: 50%;
    width: $color-size;
    height: $color-size;
    display: inline-block;
}

.color-border:hover {
    background: radial-gradient(circle, #ffffff 64%, #000000 67%, #ffffff 70%);
    cursor: pointer;
}

.color {
    @extend %centered-absolute;
    width:$color-core;
    height:$color-core;
    border-radius: 50%;
}
.color::after {
    clear: both;
    content:'';
}

.color-text {
    @extend %centered;
    font-size: 50%;
    text-align: center;
}

.multicolor {
    background-image: linear-gradient(to bottom, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}

.bin {
    cursor: pointer;
    position: relative;
    float: right;
    width: 16px;
    content: url("/assets/bin_closed.png");
}

.bin:hover {
    content: url("/assets/bin_opened.png");
}

[draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
  }

table > tbody > tr.product-new > td {
    background-color: #f4fff4;
}

table > tbody > tr.product-sold > td {
    background-color: #fff4f4;
}

table > tbody > tr.product-old > td {
    background-color: #fff4f4;
}

ul {
    padding: 0;
}

li {
    list-style: none;
    margin: 0;
    cursor: pointer;
    padding: 4px;
}

.cursor-pointer {
    cursor: pointer;
}

.form-control.oneline {
    display: initial;
    width:initial;
    margin-left: 1em;
}

img.market {
    width: 50%;
    display: block;
}

input[type=file] {
    display:none;
}


/* orders */
table > tbody > tr.order-new > td {
    background-color: #eeee9980;
}

table > tbody > tr.order-confirmed > td {
    background-color: #5555ee80;
    //color: #ffffff;
}

table > tbody > tr.order-canceled > td {
    background-color: #dddddd80;
}

table > tbody > tr.order-paid > td {
    background-color: #00990080;
    //color: #ffffff;
}

table > tbody > tr.order-invoice > td {
    background-color: #00ff0080;
    //color: #ffffff;
}

$selected-background: #ccccff;
$selected-radius: 15px;

table > tbody > tr.selected > td {
    background-color: $selected-background;
}

table > tbody > tr.selected a {
    color: #123456;
}

table > tbody > tr.selected > td:first-child {
    border-top-left-radius: $selected-radius;
}

table > tbody > tr.selected > td:last-child {
    border-top-right-radius: $selected-radius;
}

table > tbody > tr.selected + tr.selected > td:first-child, table > tbody > tr.selected + tr.selected > td:last-child {
    border-radius: 0;
}

table > tbody > tr.selected-last > td:first-child {
    border-bottom-left-radius: $selected-radius !important;
}

table > tbody > tr.selected-last > td:last-child {
    border-bottom-right-radius: $selected-radius !important;
}

table > tbody > tr.subselected > td {
    background-color: #eeeeee;
}

.sexy {
    width: 100%;
}

@media only screen and (min-width: 300px) {
    .sexy {
        width: 80%;
    }
}

@media only screen and (min-width: 600px) {
    .sexy {
        width: 50%;
    }
}

@media only screen and (min-width: 1024px) {
    .sexy {
        width: 30%;
    }
}

.commented {
    color: #bb0000;
}

.deleted {
    text-decoration: line-through;
}
